import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

// Redirect Component: Grabs URL from parameters and redirects
const RedirectPage = () => {
const [searchParams] = useSearchParams()
  const location = useLocation();

  useEffect(() => {
    // Extract the full path after '/redirect/'
    const fullPath = searchParams.get('url');
    const email = searchParams.get('email');
    const id = searchParams.get('id');
    
    // Use the full path as the redirect URL
    window.location.href = `${fullPath}?prefilled_email=${email}&client_reference_id=${id}`;
  }, [location, searchParams]);

  return <h1>Redirecting you to {searchParams.get('url')}...</h1>;
};

const CallbackPage = () => {
  const query = useQuery();

  useEffect(() => {
    // Extract callback data (example: token from the URL)
    const callbackData = query.get('token');
    console.log("🚀 ~ useEffect ~ callbackData:", callbackData)

    if (callbackData) {
      // Forward the callback data to another site or API
      fetch('http://localhost:3000/api/stripe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: callbackData }),
      })
      .then(response => response.json())
      .then(data => console.log('Forwarded successfully:', data))
      .catch(error => console.error('Error forwarding data:', error));
    }
  }, [query]);

  return <h1>Handling callback...</h1>;
};

// Root Component
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/redirect" element={<RedirectPage />} />
        <Route path="/callback" element={CallbackPage} />
      </Routes>
    </Router>
  );
};

export default App;